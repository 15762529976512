import React, { ReactElement } from "react"
const styles = require("./index.module.css")

function TermsContainer(): ReactElement {
  return (
    <div className={`max-w-1366 mx-auto ${styles.wrapper}`}>
      <div className="my-8 text-center">
        <h3 className="font-bold text-2xl">General Business Terms & Conditions</h3>
      </div>
      <p>
        <a href="#agb1">
          <strong>1. Data Protection</strong>
        </a>
      </p>
      <p>
        <a href="#agb2">
          <strong>2. Reservations</strong>
        </a>
      </p>
      <p>
        <a href="#agb3">
          <strong>3. Payment</strong>
        </a>
      </p>
      <p>
        <a href="#agb4">
          <strong>4. Deposit</strong>
        </a>
      </p>
      <p>
        <a href="#agb5">
          <strong>5. Car Category</strong>
        </a>
      </p>
      <p>
        <a href="#agb6">
          <strong>6. Pick up &amp; Return of Rental Cars</strong>
        </a>
      </p>
      <p>
        <a href="#agb7">
          <strong>7. Deliveries &amp; Collection</strong>
        </a>
      </p>
      <p>
        <a href="#agb8">
          <strong>8. Insurances</strong>
        </a>
      </p>
      <p>
        <a href="#agb9">
          <strong>9. Refundable Excess Products</strong>
        </a>
      </p>
      <p>
        <a href="#agb10">
          <strong>10. Refundable Excess </strong>
        </a>
      </p>
      <p>
        <a href="#agb11">
          <strong>11. Refundable Excess with Super Cover</strong>
        </a>
      </p>
      <p>
        <a href="#agb12">
          <strong>12. Additional Coverage</strong>
        </a>
      </p>
      <p>
        <a href="#agb13">
          <strong>13. Driving Licence</strong>
        </a>
      </p>
      <p>
        <a href="#agb14">
          <strong>14. Additional Drivers</strong>
        </a>
      </p>
      <p>
        <a href="#agb15">
          <strong>15. One-way Rentals</strong>
        </a>
      </p>
      <p>
        <a href="#agb16">
          <strong>16. Travel to other Countries</strong>
        </a>
      </p>
      <p>
        <a href="#agb17">
          <strong>17. Optional Equipment</strong>
        </a>
      </p>
      <p>
        <a href="#agb18">
          <strong>18. Customer Service</strong>
        </a>
      </p>
      <p>
        <a href="#agb19">
          <strong>19. Booking Modifications</strong>
        </a>
      </p>
      <p>
        <a href="#agb20">
          <strong>20. Cancellations</strong>
        </a>
      </p>
      <p>
        <a href="#agb21">
          <strong>21. Limits of Liability</strong>
        </a>
      </p>
      <p>
        <a href="#agb22">
          <strong>22. Privacy Policy</strong>
        </a>
      </p>
      <p>
        <a href="#agb23">
          <strong>23. Miscellaneous</strong>
        </a>
      </p>
      
      <p id="agb1">
        <strong>1. Data Protection</strong>
      </p>
      <p>
        Please note that this site is operated on servers controlled by Auto
        Europe, LLC, a US based company, and that any data which you send to us
        will be processed not only by Rent-O-Ride Deutschland GmbH Ltd but also
        by Rent-O-Ride, LLC, in the United States. If you do not want your data
        to be transferred to Rent-O-Ride, LLC, in the United States, please do
        not use this site. By using our services, you acknowledge that your data
        will be processed in the United States and recognize that the transfer
        of your data to the United States to Rent-O-Ride, LLC is required for us
        to provide you with our services.
      </p>
      <p>
        <strong>Personal Data Protection</strong>
      </p>
      <p>
        You may at any time, request to see the information which is being held
        by us on you and request to make amendments to this information or
        delete this information. Please contact us at{" "}
        <a href="mailto:data-protection@rent-o-ride.com">
          data-protection@rent-o-ride.com
        </a>{" "}
        or use our postal address: <br />
        <br />
      </p>
      <p></p>
      <p id="agb2">
        <strong>2. Reservations</strong>
      </p>
      <p>
        Generally, reservations via Rent-O-Ride are confirmed immediately.
        Exceptions can include last-minute bookings, on request bookings,
        special vehicles, one-way rentals and optional equipment. In these cases
        Rent-O-Ride has to request a confirmation from the car rental company.
        Following confirmation and payment, Rent-O-Ride sends a car rental
        voucher to the customer either by email or by fax (upon request). The
        client has to present this voucher to the car rental company at time of
        pick-up. The actual rental agreement is concluded locally between the
        customer and the car rental company and is subject to the car rental
        companys terms &amp; conditions as well as local legislation. Auto
        Europe reserves the right to change the booking to another car rental
        company - subject to availability - and to inform the customer in due
        time. [<a href="#termscontent">Back to Top</a>]
      </p>
      <p id="agb3">
        <strong>3. Payment</strong>
      </p>
      <p>
        Customer has to pay in full to Rent-O-Ride at time of reservation.
        Payment must be made by credit card. At the time of pick-up, a valid
        credit card in the name of the main driver (the name on your car hire
        booking) must be presented to the car rental company for the deposit
        (see "Deposit"). Only upon receipt of full payment Rent-O-Ride will send
        the car rental voucher - once confirmed by the car rental company - to
        the customer. All prices are based on 24-hour periods starting with the
        pick-up time. The charged price is valid for the confirmed duration.
        Variations can result in additional charges locally e.g. in case of
        early returns the car rental company might charge higher daily rates
        and/or in case of late returns the car rental company might charge the
        extra hours/days at locally applicable rates. As Rent-O-Ride is acting
        as a Disclosed Agent we are unable to issue an invoice with VAT. Should
        you need an invoice with VAT we recommend that you contact the car
        rental supplier. [<a href="#termscontent">Back to Top</a>]
      </p>
      <p>
        <strong>Special Campervan Regulations</strong>
        <br />
        For motorhome bookings the total price must be paid no later than 70
        days prior to rental. For rentals commencing outside of 70 days, a
        deposit of at least 30% of the total rental price must be paid at the
        time of booking.
      </p>
      <p id="agb4">
        <strong>4. Deposit</strong>
      </p>
      <p>
        At time of pick-up the car rental company requires a deposit. This takes
        place by an amount being blocked or charged on the international, valid
        credit card issued in the name of the renter. Debit cards and prepaid
        credit cards are not accepted. Information regarding the acceptance of
        credit cards at time of pickup can be found in the terms and conditions
        of each car rental supplier during the booking process and on the
        voucher. In general, the deposit amount includes the value of a full
        tank plus the excess in case of damages to or theft of the rental car
        plus any local charges (for optional equipment, optional insurances
        taken out locally, one-way fees etc.) [
        <a href="#termscontent">Back to Top</a>]
      </p>
      <p id="agb5">
        <strong>5. Car Category</strong>
      </p>
      <p>
        Reservations and confirmation are for car categories only, never for a
        specific make or model. Car rental companies maintain fleets with many
        different makes and models of similar size and configuration. Car rental
        companies reserve the right to provide similar or higher category cars
        to the customer than originally booked and paid for. [
        <a href="#termscontent">Back to Top</a>]
      </p>
      <p id="agb6">
        <strong>6. Pick up &amp; Return of Rental Cars</strong>
      </p>
      <p>
        Already at time of reservation the customer determines exactly where and
        when the pick-up and the return of the rental car will take place. When
        making a reservation for pick-up at an airport location, Rent-O-Ride and
        the car rental company require the flight number and arrival time.
        Should you expect to be delayed for the pickup of the vehicle, you
        should also contact us to ensure your vehicle can be confirmed after the
        scheduled timing. For pick-ups at downtown locations customers only need
        to provide the exact time. In general, car rental companies will hold
        the rental car available for pick-up until 30 to 60 minutes after the
        originally reserved time, as long as this is within the normal location
        opening hours. Pick-ups outside of normal location opening hours are on
        request and will be charged an extra fee. [
        <a href="#termscontent">Back to Top</a>]
      </p>
      <p>
        <strong>Special Campervan Regulations</strong>
        <br />
        Specified times apply to the collection and return of campervans.
        Collection and return of campervans outside these hours may be requested
        and, if available, will always incur additional charges. These fees can
        only be disclosed upon the booking confirmation. Detailed vehicle
        instructions will be given upon collection.
      </p>
      <p id="agb7">
        <strong>7. Deliveries &amp; Collections</strong>
      </p>
      <p>
        This service can be requested with the car rental companies from
        downtown locations for an extra fee. For deliveries and collections
        requests it is mandatory to provide the hotel name, address and
        telephone number. Agreed times are approximate only and can vary by up
        to 60 minutes. Deliveries and collections of rental cars are not
        possible to/from cruise terminals, private accommodation, Fincas or
        campgrounds. [<a href="#termscontent">Back to Top</a>]
      </p>
      <p id="agb8">
        <strong>8. Insurances</strong>
      </p>
      <p>
        Fees, inclusions and coverage differ by destination, car rental company
        and car category. Customers should refer to the actual rental agreements
        (in local language, often with English translations) of the car rental
        companies for details. In general, insurance claims based on the rental
        agreement can only be made when damages are reported to and recorded by
        local police. In any case it is a prerequisite that utilization of the
        rental car is in accordance with the rental agreement. Rent-O-Rides
        products and prices always include: liability insurance including
        legally required minimum coverage, CDW = Collision Damage Waiver with or
        without excess depending on the car rental company. CDW never covers
        damage to tyres, glass, exterior mirrors, underside, interior, roof,
        engine, damage to the locks and loss of vehicle keys. TP = Theft
        Protection with or without excess depending on the car rental company. [
        <a href="#termscontent">Back to Top</a>]
      </p>
      <p id="agb9">
        <strong>9. Refundable Excess Products</strong>
      </p>
      <p>
        For car rental bookings in most destinations and with most of our car
        rental partners, Rent-O-Ride is offering a Refundable Excess product for
        an additional fee. Detailed terms and conditions and prices are part of
        the car rental voucher and are being communicated at time of
        reservation. This Refundable Excess policy is NOT an insurance but a
        special service of Rent-O-Ride LLC. Rent-O-Ride will decide on the
        validity and the extent of the claim according to the companys best
        judgement. When the car rental booking includes the Refundable Excess
        product from Rent-O-Ride (not from the car rental company), customers
        need to provide the following documents in case of damage to or theft of
        the rental car: rental agreement, damage report from the car rental
        company, police report or - in case of single vehicle accidents a police
        notification, proof of payment/credit card statement. Only when all
        documents are present and wilfulness and severe negligence can be
        excluded, a refund will be processed. Rent-O-Rides Refundable Excess
        product is in no way linked to any Super CDW offered by car rental
        companies. No refund will be made when damages/accidents occurred due to
        violation of local traffic rules, breach of the rental agreement or when
        any involved third party refuses the settlement of the claim. All rental
        agreements are subject to the local legislation of the pick-up country.
        [<a href="#termscontent">Back to Top</a>]
      </p>
      <p id="agb10">
        <strong>10. Refundable Excess</strong>
      </p>
      <p>
        With Refundable Excess, damage to certain vehicle parts is always
        excluded from the refund: damage to glass, tyres, the interior, gearbox,
        underside, roof, clutch, engine, oil sump, damage caused by filling with
        wrong fuel, accidents caused by animals (including beech marten bites).
        Please refer to the voucher (the last page) for more detailed
        information on exceptions with the purchased product. Refundable Excess
        does not cover damage to or loss of personal property, vehicle theft due
        to the loss of vehicle keys, car rental suppliers income loss for the
        vehicle which cannot be used, roadside assistance, towing charges, any
        processing fees in the event of damage (if applicable), administrative
        fees nor any subsequent personal costs such as telephone bills, hotel
        accommodation, public transportation or taxi costs. A refund is also
        impossible if the accident has been caused by breaking the local traffic
        rules and/or if the driver has been issued with a fine, and/or when the
        insurance company (even of the other party involved) has rejected the
        claims settlement on the spot. All rental agreements are subject to the
        local legislation of the pick-up country. [
        <a href="#termscontent">Back to Top</a>]
      </p>
      <p id="agb11">
        <strong>11. Refundable Excess with SUPER COVER</strong>
      </p>
      <p>
        Refundable Excess with Super Cover excludes: damage to the interior and
        gears. For more details and the individual exclusions of the purchased
        product, please refer to the voucher (the last page ). This product also
        does not cover any damage to or loss of personal property, vehicle theft
        due to loss of vehicle keys, car rental suppliers loss of income for the
        vehicle which cannot be used, roadside assistance, towing charges, any
        processing fees in the event of damage (if applicable), administrative
        fees nor any subsequent personal costs such as telephone bills, hotel
        accommodation, public transportation or taxi costs. A refund is also
        impossible if the accident has been caused by breaking the local traffic
        rules and/or if the driver has been issued with a fine, and/or when the
        insurance company (even of the other party involved) has rejected the
        claims settlement on the spot. All rental agreements are subject to the
        local legislation of the pick-up country. [
        <a href="#termscontent">Back to Top</a>]
      </p>
      <p>
        <strong>Special Campervan Regulations</strong>
        <br />
        Rent-O-Ride does not offer Refundable Excess for campervans.
      </p>
      <p id="agb12">
        <strong>12. Additional Coverage</strong>
      </p>
      <p>
        At time of pick-up the car rental company might offer you additional
        coverage including but not restricted to PAI (Personal Accident
        Insurance), PEC (Personal Effects Coverage), Super CDW, Seguro Relax
        Insurance, Road Assistance etc. Rent-O-Ride does not refund any locally
        purchased insurance charges/fees even if deemed unnecessary after return
        of the rental car. [<a href="#termscontent">Back to Top</a>]
      </p>
      <p id="agb13">
        <strong>13. Driver License</strong>
      </p>
      <p>
        Renters and additional authorized drivers must present a valid driving
        licence at time of pick-up which they must have held for at least 1
        year, exceptionally for 3 years or more (for details see voucher terms
        &amp; conditions). If the valid national driving licence is not
        completely in the Roman alphabet, then an International Driving Permit
        (IDP) is mandatory, valid only in addition to the national driving
        licence. Some car rental suppliers always require an IDP at time of
        pick-up even if the original national driver license is in the Roman
        alphabet (for details see voucher terms &amp; conditions). All car
        rental suppliers have minimum age requirements. Some of the suppliers
        also have a maximum age limit. This is valid for both the main and the
        additional driver. [<a href="#termscontent">Back to Top</a>]
      </p>
      <p>
        <strong>Special Campervan Regulations</strong>
        <br />
        Please note, depending on the campervans permissible total weight, a
        driving licence category C1 (European Driving License) will be required.
      </p>
      <p id="agb14">
        <strong>14. Additional Drivers</strong>
      </p>
      <p>
        With some car rental companies the fees for additional drivers are
        already included in the price; others charge a daily fee at time of
        pick-up. Such fees are stated on Rent-O-Rides website and on the car
        rental voucher. In any case, additional drivers need to be registered in
        the rental agreement and need to present the same documents as the
        renter at time of pick-up (driving licence and International Driving
        Permit where required and passport/personal ID). [
        <a href="#termscontent">Back to Top</a>]
      </p>
      <p id="agb15">
        <strong>15. One-way Rentals</strong>
      </p>
      <p>
        One-way rentals are available upon request and for a fee in most
        destinations and with most car rental companies. Rent-O-Ride will
        request one-way rentals and applicable fees (to be paid locally) with
        the car rental companies and will communicate such fees to the customers
        after the reservation has been made. The customer is entitled to cancel
        the whole booking free of charge if the customer does not agree with the
        one-way fee. Restrictions may apply for certain car groups, minimum
        durations and between certain cities and locations. [
        <a href="#termscontent">Back to Top</a>]
      </p>
      <p id="agb16">
        <strong>16. Travel to other Countries</strong>
      </p>
      <p>
        Customers must indicate travel to other countries at time of
        reservation. All countries must be noted on the voucher. In most cases,
        the car rental supplier must authorize any cross-border travel and will
        charge a daily fee locally. Such fee is stated on the vouchers terms
        &amp; conditions. [<a href="#termscontent">Back to Top</a>]
      </p>
      <p id="agb17">
        <strong>17. Optional Equipment</strong>
      </p>
      <p>
        Optional equipment like child seats, roof racks, winter equipment etc.
        must be requested at time of reservation and is subject to availability
        to be confirmed by the car rental supplier. Applicable fees are noted on
        the car rental voucher and are paid locally. [
        <a href="#termscontent">Back to Top</a>]
      </p>
      <p id="agb18">
        <strong>18. Customer Service</strong>
      </p>
      <p>
        Rent-O-Ride will support customers in solving any problems with the car
        rental company which are not the customer's fault. As a broker, Auto
        Europe cannot be held liable for the provision of services (incl.
        insurances) by the car rental company as per rental agreement and/or the
        refund of charges from the car rental company. Customers must send any
        claim in writing to Rent-O-Ride within 90 days after the rental period.
        [<a href="#termscontent">Back to Top</a>]
      </p>
      <p id="agb19">
        <strong>19. Booking Modifications</strong>
      </p>
      <p>
        Booking modifications can be made up to 48 hours prior to the pick-up
        time and are subject to availability and additional charges. Rent-O-Ride
        does not charge for booking modifications but applies the prices which
        are applicable at time of amendment of the booking. No modifications can
        be made after pick-up time: no extensions of the rental duration, no
        refund of unused voucher days if car is picked up late or returned
        early. Any changes of bookings (flight details, arrival time, car
        category etc.) must be communicated to Rent-O-Ride and NOT directly to
        the car rental company. Please note that a name change of the renter is
        not a booking modification but constitutes a cancellation of the
        original booking and a new booking under the new renter's name. [
        <a href="#termscontent">Back to Top</a>]
      </p>
      <p>
        <strong>Special Campervan Regulations</strong>
        <br />
        Booking modification is only possible prior to the rental date and is
        subject to availability and additional charges. The charges will reflect
        the cancellation costs incurred at the time of the booking modification
        and the new rental price valid at the time of booking modification.
      </p>
      <p id="agb20">
        <strong>20. Cancellations</strong>
      </p>
      <p>
        Rent-O-Ride has no cancellation fee when Rent-O-Ride is advised 48 hours
        prior to the reserved rental start date. Rent-O-Ride does not charge any
        amendment fee up to 48 hours prior to rental start date however should a
        reservation be amended the client will have to pay the rate available at
        the time of change. No refund is possible for cancellations less than 48
        hours prior to the reserved rental start date &amp; time and for
        cancellations after the planned pick up date. Rent-O-Ride does not
        refund should the client show up to the counter without a valid driver's
        license. Rent-O-Ride does not refund should the client present an
        unacceptable credit card at the counter or a credit card with not enough
        credit for the deposit . Rent-O-Ride does not refund any unused days if
        the customer does not show up, shows up over 2 hours late without
        contacting Rent-O-Ride or returns the vehicle early. For cancellations
        of confirmed and paid bookings of 7- and 9-seater vans with pick-up
        anywhere in Europe during July and August no refund will be made,
        regardless of the cancellation date and time. Customers have the right
        to provide proof in writing of a lesser financial damage and
        subsequently lower the cancellation cost.[
        <a href="#termscontent">Back to Top</a>]
      </p>
      <p>
        <strong>Special Campervan Regulations</strong>
        <br />
        The cost of cancellation of campervan hire depends on the date of
        cancellation and will be shown separately on the quotation or the
        booking voucher.
      </p>
      <p id="agb21">
        <strong>21. Limits of Liability</strong>
      </p>
      <p>
        As a direct agent, Rent-O-Ride is exclusively liable for the orderly
        placement of the car rental booking with the car rental company, that
        is, for the procurement of the opportunity to complete the car rental on
        the agreed conditions. Rent-O-Ride is not liable if rental agreements
        are not effected locally for reasons which the customer is responsible
        for including but not restricted to missing or invalid documents at time
        of pick-up (driving licence, passport/personal ID, credit card,
        voucher), not fulfilling rental requirements like minimum/maximum age,
        incapability of driving due to consumption of alcohol, drugs,
        prescriptions etc. Other than that Rent-O-Ride is only liable for
        compensation of financial damages due to willful or severely negligent
        acts by its employees. In the event of simple negligence, Rent-O-Rides
        liability and the amount of compensation shall be limited to the
        foreseeable losses typical of the contract and only in the event of a
        breach of contractual duty, whose compliance is of a particular
        importance for fulfilling the contract. Rent-O-Ride shall be liable for
        cases which are impossible from the outset only if the impediment upon
        fulfilment was known to Rent-O-Ride or if its lack of knowledge arose
        from gross negligence. The aforementioned limitation or exclusion of
        liability shall not apply in the case of damage or injury to life, body,
        and health. In the event that our liability should be excluded or
        limited, the same shall also apply to the liability of Rent-O-Rides
        employees, representatives, and agents. With the exception of claims
        based on tortious acts, the liability for damage shall expire after one
        year. Rent-O-Ride is not responsible or liable for any claims based on
        the contractual rental agreement between the customer and the car rental
        company or for the rental car itself. In such cases customers need to
        contact the car rental company as their contractual partner. Rent-O-Ride
        is not responsible or liable for the rented vehicle itself, in
        particular not for its technical safety nor for the vehicle being
        suitable for the client's needs. In this respect, the customer's claims
        are exclusively limited to the supplier in question/on the spot. [
        <a href="#termscontent">Back to Top</a>]
      </p>
      <p id="agb22">
        <br />
        <br />
        <strong>22. Privacy Policy</strong>
      </p>
      <p>
        Rent-O-Ride regards the protection of your personal data our major
        responsibility. Below we would like to inform you about the data we
        process and its purpose in accordance with the relevant data protection
        laws.
      </p>
      <p>
        <strong>
          1. The responsible authority for data processing in Europe is:
        </strong>
      </p>
      <br />
      Rent-O-Ride Deutschland GmbH
      <br />
      Landsbergerstrasse 155
      <br />
      80687 Munich
      <br />
      Germany
      <br />
      <br />
      If you have questions or comments about our data protection privacy policy
      in general or the assertion of your rights (see No.6), you can contact us
      at{" "}
      <a href="mailto:data-protection@rent-o-ride.com">
        data-protection@rent-o-ride.com
      </a>{" "}
      or contact our data protection officer in writing by post:
      <br />
      <br />
      Ms Kirsten Waidmann
      <br />
      Rent-O-Ride Deutschland GmbH
      <br />
      Landsbergerstrasse 155
      <br />
      80687 Munich
      <br />
      Germany
      <br />
      <p></p>
      <p>
        <strong>2. Purpose of Data Processing</strong>
      </p>
      <p>We collect and process data for the following reasons:</p>
      <ul>
        <li>To ensure the functionality and performance of the website.</li>
        <li>
          To process the booking of rental cars, their delivery and payment
          (contract processing).
        </li>
        <li>
          To share interesting offers and news through our newsletter assuming
          you as a client have agreed to receive these offers.
        </li>
      </ul>
      <p>
        <strong>3. Saved Data</strong>
      </p>
      <p>
        Basically, it is possible to visit our website without providing any
        personal data.
        <br />
        <br />
        For better performance and to guarantee our website's functionality, we
        process the data transmitted to us by your Internet browser. These
        include the date and time of your visit, browser settings, the operating
        system and the IP address.
        <br />
        <br />
        To process a rental car booking, we need your name, address, age of the
        driver, contact information such as an email address or a phone number,
        credit card or bank information. In order to be able to guarantee
        certain offers, we may need your date of birth, driving licence and ID
        information.
        <br />
        <br />
        To send you information and offers via our newsletter, we require your
        email address.
        <br />
      </p>
      <p>
        <strong>4. Recipients or Categories of Recipients</strong>
      </p>
      <p>
        Car rental bookings are handled by our mother company Rent-O-Ride LLC, a
        US-based company headquartered in Portland, ME 04112, USA. The data
        which you provide in a form on our website, such as those described in
        the Section 3, will be transmitted to Rent-O-Ride LLC, our payment
        service provider including Fraud Prevention, and your chosen car rental
        company, with the purpose of processing your car rental booking.
        <br />
        <br />
        We also reserve the right to transfer your data to debt collection
        agencies and business protection organizations (such as credit
        investigation companies).
        <br />
        <br />
        It is possible that the recipients of the data may be located in
        countries outside the European Economic Area (third countries) where the
        applicable law does not offer a comparable level of data protection. In
        such cases, Rent-O-Ride ensures that an adequate level of data
        protection is guaranteed in other ways.
        <br />
      </p>
      <p>
        <strong>5. Data Security</strong>
      </p>
      <p>
        To protect your privacy, all booking inquiries and related payment
        transactions that reach us through our website are processed with HTTPS
        secure encryption using state of the art encryption (SSL / TLS). You
        will know that your browser uses this secure transmission as it is
        indicated by the Internet address beginning with "https" and a small
        lock symbol (usually) displayed in the address bar of your browser
        window. When the lock is closed, it is a safe transfer. Some browsers
        show the address bar or part of it in green colour for this case.
        (Please note that these security settings are only available on pages
        where you can see and send your request or submit your credit card
        details.)
        <br />
      </p>
      <p>
        <strong>6. Enforcement of Rights</strong>
      </p>
      <p>
        You have the right to request information about the personal data that
        we hold about you and to request the correction, deletion or restriction
        of the processing of this data at any time. To assert these rights,
        please use the contact options listed under No. 1.
        <br />
      </p>
      <p>
        <strong>7. Right of Appeal at Supervisory Authorities</strong>
      </p>
      <p>
        In addition, you have the right to lodge a complaint with the competent
        supervisory authority. The supervisory authority responsible for Auto
        Europe Deutschland GmbH is as follows
        <br />
        <br />
        Bayerische Landesamt für Datenschutzaufsicht
        <br />
        Promenade 27
        <br />
        91522 Ansbach
        <br />
        Germany
        <br />
      </p>
      <p>
        <strong>8. Use of Cookies</strong>
      </p>
      <p>
        Rent-O-Ride uses cookies on our website. Cookies are small text files
        that are sent from our web server to your browser during your visit to
        our website that are stored by this on your computer for later
        retrieval.
        <br />
        <br />
        The purpose of these cookies is to continue to recognize your computer
        during a visit to our website when switching from one of our websites to
        another of our websites and to be able to determine the end of your
        visit.
        <br />
        <br />
        We also use these technologies to collect usage and preference
        information about you over time and across different platforms. So do
        our selected business partners, including our advertising partners.
        These companies use certain technologies to deliver adverts and
        marketing messages to you after visiting our website. Of course, you can
        also view our website without cookies. Internet browsers are regularly
        set to accept cookies. You can deactivate the use of cookies at any time
        in the settings of your browser. Please use the help features of your
        internet browser to find out how to change these settings. Please note
        that some features of our website may not work if you disable the use of
        cookies.
        <br />
        <br />
        The collection or storage of personal data in cookies does not take
        place in this context by us. We also do not use techniques that combine
        information generated by cookies with user data.
        <br />
        <br />
        If you prefer to delete the Rent-O-Ride cookies, please click{" "}
        <a href="/cookiecutter.html">here</a>.<br />
      </p>
      <p>
        <strong>9. Google Analytics</strong>
      </p>
      <p>
        Our website uses Google Analytics, a web analytics service provided by
        Google Inc. ("Google"). Cookies are stored on your computer, which
        allows an analysis of your use of the website.
        <br />
        <br />
        The information generated by the cookie about your use of this website
        is usually transmitted to a Google server in the USA and stored there.
        We use the service on our website with activated IP anonymity. This
        means that on our website, your IP address will be abbreviated
        beforehand by Google within the member states of the European Union or
        in other contracting states of the Agreement in the European Economic
        Area and therefore anonymous. Only in exceptional cases will the full IP
        address be transferred to a Google server in the USA and abbreviated
        there. On our behalf, Google will use this information to evaluate your
        use of the website, to compile reports on website activity and to
        provide other services related to the website activity and internet
        usage to us as website operators. For more information, please visit:{" "}
        <a
          href="https://policies.google.com/technologies/partner-sites?hl=en"
          rel="noopener"
        >
          https://policies.google.com/technologies/partner-sites
        </a>
        <br />
        <br />
        The IP address obtained from your browser and used by Google Analytics
        will not be merged with other Google data.
        <br />
        <br />
        You can prevent the storage of cookies in your browser in the settings
        of your browser software (see 8.). Please note that some features of our
        website may not work if you disable the use of cookies. Furthermore, it
        is possible to prevent the collection by Google Analytics by setting an
        opt-out cookie. Please click on the following link:
        <br />
        <a href="https://www.rent-o-ride.co.uk/privacy/?ga_out=Y">
          https://www.rent-o-ride.co.uk/privacy/?ga_out=Y
        </a>
        <br />
        <br />
        In addition, you may prevent the collection and use of the data
        generated by the cookie and related to your use of the website
        (including your IP address) as well as the processing of this data by
        Google by downloading and installing the browser plug-in available via
        the following link:{" "}
        <a href="http://tools.google.com/dlpage/gaoptout?hl=en" rel="noopener">
          http://tools.google.com/dlpage/gaoptout?hl=en
        </a>
        <br />
      </p>
      <p>
        <strong>10. Newsletter</strong>
      </p>
      <p>
        On our website, you are offered the chance to receive information about
        our offers via newsletter. We use the so-called double opt-in procedure
        for the registration. After you have given us your email address, we
        will send you a confirmation email. Only when you click on the
        confirmation link contained in the email, will your email address be
        added to our mailing list. This ensures that only the actual owner of
        the email address can order the newsletter. Data processing in the
        context of the newsletter is based on your consent.
        <br />
        <br />
        You have the right to revoke this consent at any time for future
        newsletter emails.
        <br />
        <br />
        You can unsubscribe <a href="/unsubscribe/">here</a> from the newsletter
        and thus revoke your consent.
        <br />
      </p>
      <p>
        <strong>11. Other Communications</strong>
      </p>
      <p>
        Rent-O-Ride will also send you a "pre-departure email" prior to pick-up
        containing some useful advice and reminders of what you will need to
        take with you to the car rental counter. After you have completed your
        rental, we may send you a questionnaire or ask you to review your
        experience with us.
        <br />
      </p>
      <p>
        <strong>12. Links</strong>
      </p>
      <p>
        Our website may contain links to other platforms. If you are redirected
        to another website by clicking on one of the links, the privacy policy
        of Rent-O-Ride Deutschland GmbH no longer applies. Instead, the policy
        of that respective website operator will apply.
        <br />
      </p>
      <p>
        <strong>13. Changes in Data Security</strong>
      </p>
      <p>
        We reserve the right to change this privacy policy in order to adapt it
        to the new functions on the website or a changed legal situation. The
        current policy in force at the time of your visit to our website will be
        valid.
        <br />[<a href="#top">Back to top</a>]
      </p>
      <p id="agb23">
        <strong>23. Miscellaneous</strong>
      </p>
      <p>
        The ineffectiveness of single regulations of the rental contract does
        not result in the ineffectiveness of the entire contract. In addition,
        the generally applicable legal regulations apply. For all consumers of
        the European Union the conditions of this agreement and all objections
        related to the payment are subject to the law of the United Kingdom. [
        <a href="#termscontent">Back to Top</a>]
      </p>
      
    </div>
  )
}

export default TermsContainer
